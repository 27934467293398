import Control from 'ol/control/Control';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

export class GeolocControl extends Control {

  public constructor (opts?: { target?: string | HTMLElement }) {
    const options = opts || {};

    const button = document.createElement('button');
    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" /></svg>';
    button.title = 'Localiser ma position';

    const element = document.createElement('div');
    element.className = 'geoloc ol-unselectable ol-control';
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.goToMyPosition.bind(this), false);
  }

  public async goToMyPosition () {
    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      await new Promise((resolve) => {
        this.getMap()?.getView().fit(
          new Point(fromLonLat([ position.coords.longitude, position.coords.latitude ])),
          {
            maxZoom: 17,
            duration: 1000,
            callback: resolve,
          },
        );
      });
    }
    catch (err) {
      console.error('Error getting position', err);
      // eslint-disable-next-line no-alert
      alert('Votre navigateur n\'autorise pas la géolocalisation, veuillez modifier les paramètres dans les réglages et rechargez la carte.');
    }
  }
}
