import { Feature } from 'ol';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';

import aeIcon from './assets/ae-icon.png';
import travauxIcon from './assets/travaux-icon.png';

export const  aeStyle = new Style({
  stroke: new Stroke({
    width: 5,
    color: '#F94B3F',
  }),
});
export const aePointStyle = new Style({
  image: new Icon({
    src: travauxIcon,
    height: 30,
    width: 30,
  }),
});
export const aeIntendedPointStyle = new Style({
  image: new Icon({
    src: aeIcon,
    height: 30,
    width: 30,
  }),
});

export const aePointStyleSelected = new Style({
  image: new CircleStyle({
    radius: 7,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#003278',
    }),
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 1,
  }),
});

const styleCache: Record<number, Style | undefined> = {};
export function clusterStyleFn (feature: Feature) {
  const size = feature.get('features').length;
  if (size === 1) {
    return feature.get('features')[0]!.getStyle();
  }

  let style = styleCache[size];
  if (!style) {
    style = new Style({
      image: new CircleStyle({
        radius: 18,
        fill: new Fill({
          color: '#003278',
        }),
      }),
      text: new Text({
        text: size.toString(),
        font: '17px "Work Sans", sans-serif',
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
    styleCache[size] = style;
  }
  return style;
}
